import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/',
    },
    {
        id: 3,
        title: 'Industries',
        link: '',
        submenu: [
            {
                id: 31,
                title: 'BFSI',
                link: '/industry-details/BFSI-Industry-AI-Solutions'
            },
            {
                id: 3222,
                title: 'Manufacturing',
                link: '/industry-details/Manufacturing-Industry'
            },
            {
                id: 322,
                title: 'Education',
                link: '/industry-details/Education-Industry'
            },
            {
                id: 345,
                title: 'Enterprise Technologies',
                link: '/industry-details/Enterprise-Technology-Industry'
            },
            {
                id: 3454,
                title: 'Media & Entertainment',
                link: '/industry-details/Media-&-Entertainment-Industry'
            },
            {
                id: 345,
                title: 'Telecom Industry',
                link: '/industry-details/Telecom-Industry'
            },
            {
                id: 345,
                title: 'Travel & Hospitality',
                link: '/industry-details/Travel-&-Hospitality'
            },
            {
                id: 345,
                title: 'Healthcare',
                link: '/industry-details/Healthcare'
            }
            
        ]
    },
    {
        id: 4,
        title: 'Our Services',
        link: '',
        submenu: [
            {
                id: 41,
                title: 'Data Management Services',
                link: '/our-services/Data-Management-Services'
            },
            {
                id: 42,
                title: 'Data Visualization',
                link: '/our-services/Data-Visualization-Services'
            },
            {
                id: 42,
                title: 'Business Intelligence',
                link: '/our-services/Business-Intelligence'
            },
            {
                id: 42,
                title: 'Software Architecture Design',
                link: '/our-services/Software-Architecture-Design'
            },
            {
                id: 42,
                title: 'Data Migration Services',
                link: '/our-services/Data-migration-services'
            },
            {
                id: 42,
                title: 'Techgaurd Audit',
                link: '/our-services/TechGuard-Audit'
            },
            {
                id: 42,
                title: 'Cybersafe Audit',
                link: '/our-services/CyberSafe-Audit-&-IT-Consulting'
            },
            {
                id: 42,
                title: 'Web App Development',
                link: '/our-services/Web-App-Development-Services'
            },
            {
                id: 42,
                title: 'Website Development',
                link: '/our-services/Website-Development-Services'
            },
            {
                id: 42,
                title: 'Decision Automations',
                link: '/our-services/Decision-Automation'
            },
            {
                id: 42,
                title: 'Automation Assistance Services',
                link: '/our-services/Automation-Assessment-Services'
            },

        ]
    },
    {
        id: 5,
        title: 'About Us',
        link: '',
        submenu: [
            {
                id: 51,
                title: 'Company Overview',
                link: '/about'
            },
            {
                id: 52,
                title: 'Careers',
                link: 'https://glorinz.zohorecruit.com/jobs/Careers'
            }
        ]
    },
    {
        id: 6,
        title: 'Company Profile',
        link: '/images/glorious-company-profile.pdf',
    },
    {
        id: 88,
        title: 'Contact Us',
        link: '/contact',
    }


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <ul className="main_menu_list clearfix">
            {menus.map((item, mn) => {
                return (
                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                        {item.submenu ?
                            <Fragment>
                                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                    <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                </p>
                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                    <List className="subMenu">
                                        <Fragment>
                                            {item.submenu.map((submenu, i) => {
                                                return (
                                                    <ListItem key={i}>
                                                        <Link onClick={ClickHandler} className="active"
                                                            to={submenu.link}>{submenu.title}</Link>
                                                    </ListItem>
                                                )
                                            })}
                                        </Fragment>
                                    </List>
                                </Collapse>
                            </Fragment>
                            : <Link className="active"
                                to={item.link}>{item.title}</Link>
                        }
                    </ListItem>
                )
            })}
        </ul>
    )
}

export default MobileMenu;