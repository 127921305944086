import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import icon1 from '../../images/icons/icon_wifi.svg'
import icon2 from '../../images/icons/icon_dollar_2.svg'
import icon3 from '../../images/icons/icon_chart.svg'
import icon4 from '../../images/icons/icon_tag_2.svg'
import icon5 from '../../images/icons/icon_user_2.svg'
import icon6 from '../../images/icons/icon_users.svg'
import icon7 from '../../images/icons/icon_pen.svg'
import logo from '../../images/site_logo/site_logo_2.png'
import logo2 from '../../images/site_logo/site_logo_3.png'
import MobileMenu from '../MobileMenu/MobileMenu'

const Header2 = (props) => {

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <header className="site_header site_header_2">
            <div className={`header_bottom stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-2 col-5">
                            <div className="site_logo">
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={logo} alt="" />
                                    <img src={logo2} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-2">
                            <nav className="main_menu navbar navbar-expand-lg">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                    <ul className="main_menu_list unordered_list justify-content-center">
                                    <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="company_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Industries
                                            </Link>
                                            <div className="dropdown-menu mega_menu_wrapper" aria-labelledby="company_submenu">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-9">
                                                            <div className="megamenu_pages_wrapper mb-5">
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/BFSI-Industry-AI-Solutions">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon1} alt="Wifi SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">BFSI</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            As banking embraces technology, we drive their digital innovation by leveraging data, guiding them towards the next evolution in financial services.
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Manufacturing-Industry">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon2} alt="Dollar SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Manufacturing</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            Leverage AI and analytics to build a strategy that drives profitability and mitigates challenges. Let Glorious Insight help you grow smartly through adversity.
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Education-Industry">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon3} alt="Chart SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Education</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            We are narrowing the gap between existing educational models and the necessary transformation for evolution by leveraging cognitive technology.
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Enterprise-Technology-Industry">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon4} alt="Event Tag SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Enterprise Technologies</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            Discover how Glorious Insight’s services and solutions can transform your enterprise’s digital challenges into business success.
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Media-&-Entertainment-Industry">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon5} alt="User Check SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Media & Entertainment</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            Learn more about our solutions in Media & Entertainment.
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Telecom-Industry">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon6} alt="Users SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Telecom Industry</small>
                                                                            </span>
                                                                            <span className="description mb-0">
                                                                            Learn more about our solutions in Telecom industry.
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Travel-&-Hospitality">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon7} alt="Pen SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Travel & Hospitality</small>
                                                                            </span>
                                                                            <small className="description mb-0">
                                                                            Learn more about our solutions in Travel & Hospitality industry.
                                                                            </small>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="col-lg-3 col-md-6">
                                                                        <Link onClick={ClickHandler} className="iconbox_block_2" to="/industry-details/Healthcare">
                                                                            <span className="icon_title_wrap">
                                                                                <small className="iconbox_icon">
                                                                                    <img src={icon7} alt="Pen SVG Icon" />
                                                                                </small>
                                                                                <small className="iconbox_title">Healthcare</small>
                                                                            </span>
                                                                            <small className="description mb-0">
                                                                            Learn more about our solutions in Healthcare industry.
                                                                            </small>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="site_author bg-primary">
                                                                <div className="author_box">
                                                                    
                                                                    <div className="author_box_content">
                                                                        <h3 className="author_name text-white">Vinay Prakash</h3>
                                                                        <span className="author_designation text-white">CEO At Glorious Insight</span>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <p className="mb-0 text-white">
                                                                    As a CEO at Glorious Insight  I have been voice crying in the wilderness,  trying to make requirements clear, use every minute to deliver the  result, and not reinvent the wheel. Here at Glorious Insight, I made that possible  for the clients.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> 
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="services_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Our Services
                                            </Link>
                                            <div className="dropdown-menu mega_menu_wrapper p-0" aria-labelledby="services_submenu">
                                                <div className="container">
                                                    <div className="row justify-content-lg-between">
                                                        <div className="col-lg-12">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <div className="megamenu_widget">
                                                                        <h3 className="megamenu_info_title">Data & AI Analytics Services</h3>
                                                                        <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Data-Management-Services">
                                                                                    <span className="icon_list_text">
                                                                                    Data Management Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Data-Visualization-Services">
                                                                                    <span className="icon_list_text">
                                                                                    Data Visualization Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Customer-Data-Platform">
                                                                                    <span className="icon_list_text">
                                                                                    Customer Data Platform
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Business-Intelligence">
                                                                                    <span className="icon_list_text">
                                                                                    Business Intelligence
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="megamenu_widget">
                                                                        <h3 className="megamenu_info_title">Custom Software Development</h3>
                                                                        <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Software-Architecture-Design">
                                                                                    <span className="icon_list_text">
                                                                                    Software architecture design
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/System-integration-services">
                                                                                    <span className="icon_list_text">
                                                                                    System integration services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Data-migration-services">
                                                                                    <span className="icon_list_text">
                                                                                    Data migration services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Legacy-app-modernization">
                                                                                    <span className="icon_list_text">
                                                                                    Legacy app modernization
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="megamenu_widget">
                                                                        <h3 className="megamenu_info_title">IT Consultation Services</h3>
                                                                        <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/TechGuard-Audit">
                                                                                    <span className="icon_list_text">
                                                                                    TechGuard Audit
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/CyberSafe-Audit-&-IT-Consulting">
                                                                                    <span className="icon_list_text">
                                                                                    CyberSafe Audit & IT Consulting
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/AssuranceEdge-&-IT-Consulting">
                                                                                    <span className="icon_list_text">
                                                                                    AssuranceEdge & IT Consulting
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="megamenu_widget">
                                                                        <h3 className="megamenu_info_title">Web Application Design and Development</h3>
                                                                        <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Web-App-Development-Services">
                                                                                    <span className="icon_list_text">
                                                                                    Web App Development Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Web-Portal-Development-Services">
                                                                                    <span className="icon_list_text">
                                                                                    Web Portal Development Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Website-Development-Services">
                                                                                    <span className="icon_list_text">
                                                                                    Website Development Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Offshore-Web-Development">
                                                                                    <span className="icon_list_text">
                                                                                    Offshore Web Development
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="megamenu_widget">
                                                                        <h3 className="megamenu_info_title">Intelligence Automation</h3>
                                                                        <ul className="icon_list unordered_list_block">
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Automation-Assessment-Services">
                                                                                    <span className="icon_list_text">
                                                                                    Automation Assessment Services
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Decision-Automation">
                                                                                    <span className="icon_list_text">
                                                                                    Decision Automation
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Content-Management-Automation">
                                                                                    <span className="icon_list_text">
                                                                                    Content Management Automation
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link onClick={ClickHandler} to="/our-services/Workflow-Automation">
                                                                                    <span className="icon_list_text">
                                                                                    Workflow Automation
                                                                                    </span>
                                                                                </Link>
                                                                            </li>
                                                                
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="dropdown">
                                            <Link onClick={ClickHandler} className="nav-link" to="/" id="pages_submenu" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                About Us
                                            </Link>
                                            <ul className="dropdown-menu" aria-labelledby="pages_submenu">
                                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                                {/* <li><Link onClick={ClickHandler} to="/contact">Company Overview</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contact">How We Work</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contact">Our Presence</Link></li>
                                                <li><Link onClick={ClickHandler} to="/contact">Social Responsibility</Link></li> */}
                                                <li><Link onClick={ClickHandler} to="https://glorinz.zohorecruit.com/jobs/Careers">Careers <small className="badge bg-danger-subtle text-danger">We’re Hiring</small></Link></li>
                                            </ul>
                                        </li>
                                        {/* <li><Link onClick={ClickHandler} to="">Blogs</Link></li> */}
                                        <li>
  <a href="/images/glorious-company-profile.pdf" target="_blank" rel="noopener noreferrer">
    Company Profile
  </a>
</li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-5">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} className="btn btn-primary" to="/contact">
                                        <span className="btn_label" data-text="Get Started">Get Started</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <div className="xb-header-menu-scroll">
                            <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                            <nav className="xb-header-nav">
                                <MobileMenu />
                            </nav>
                        </div>
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>
        </header>

    )
}

export default Header2;