import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import sanitizeHtml from 'sanitize-html'; // Make sure to install sanitize-html library

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        company: '',
        phone: '',
        message: ''
    });

    const [validator] = useState(() => new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [userAnswer, setUserAnswer] = useState('');
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                resetForm();
            }, 2000); // 2 seconds delay
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    function generateCaptcha() {
        const min = 10;
        const max = 40;
        let num1 = Math.floor(Math.random() * (max - min + 1)) + min;
        let num2 = Math.floor(Math.random() * (max - min + 1)) + min;
        const operation = Math.random() > 0.5 ? '+' : '-';
        
        let answer;
        let question;
    
        if (operation === '+') {
            answer = num1 + num2;
            question = `${num1} + ${num2}`;
        } else {
            if (num1 < num2) {
                [num1, num2] = [num2, num1];
            }
            answer = num1 - num2;
            question = `${num1} - ${num2}`;
        }
    
        return {
            question: question,
            answer: answer
        };
    }

    const sanitizeInput = (input) => {
        return sanitizeHtml(input, {
            allowedTags: [], // No HTML tags allowed
            allowedAttributes: {} // No HTML attributes allowed
        });
    };

    const changeHandler = e => {
        const { name, value } = e.target;
        setForms({ ...forms, [name]: sanitizeInput(value) });
    };

    const handleBlur = e => {
        const { name } = e.target;
        validator.showMessageFor(name); // Show validation message only for the field being interacted with
    };

    const handleCaptchaChange = e => {
        setUserAnswer(sanitizeInput(e.target.value));
        validateCaptcha();
    };

    const validateCaptcha = () => {
        if (parseInt(userAnswer) === captcha.answer) {
            setIsCaptchaValid(true);
            setError(''); // Clear error state when captcha is valid
        } else {
            setIsCaptchaValid(false);
            setError('Captcha is incorrect'); // Set error state when captcha is invalid
        }
    };

    const submitHandler = async e => {
        e.preventDefault();
        if (validator.allValid() && isCaptchaValid) {
            setIsSubmitting(true);
            setError('');

            try {
                const response = await fetch('/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(forms),
                });

                const contentType = response.headers.get('Content-Type');

                if (response.ok) {
                    if (contentType.includes('application/json')) {
                        const result = await response.json();
                        setSuccessMessage(result.message || 'Your message was sent successfully!');
                    } else {
                        const text = await response.text();
                        setSuccessMessage(text || 'Your message was sent successfully!');
                    }
                } else {
                    if (contentType.includes('application/json')) {
                        const result = await response.json();
                        setError(result.error || 'Failed to submit the form');
                    } else {
                        const text = await response.text();
                        setError(text || 'Failed to submit the form');
                    }
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                setError('Error submitting form');
            } finally {
                setIsSubmitting(false);
            }
        } else {
            validator.showMessages(); // Show validation messages if needed
        }
    };

    const resetForm = () => {
        setForms({
            name: '',
            email: '',
            company: '',
            phone: '',
            message: ''
        });
        setCaptcha(generateCaptcha()); // Generate new captcha
        setUserAnswer('');
        setIsCaptchaValid(false);
        setSuccessMessage('');
        setError('');
        validator.hideMessages(); // Clear all validation messages
    };

    return (
        <>
            <form className="xb-item--form contact-form" onSubmit={submitHandler}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title" htmlFor="input_name">
                                <i className="fa-regular fa-user"></i>
                            </label>
                            <input
                                value={forms.name}
                                type="text"
                                name="name"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={changeHandler}
                                placeholder="Your Name"
                                autoComplete="off" />
                            {validator.message('name', forms.name, 'required|alpha_space')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title" htmlFor="input_email">
                                <i className="fa-regular fa-envelope"></i>
                            </label>
                            <input
                                value={forms.email}
                                type="email"
                                name="email"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={changeHandler}
                                placeholder="Your Email"
                                autoComplete="off" />
                            {validator.message('email', forms.email, 'required|email')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title" htmlFor="input_phone">
                                <i className="fa-regular fa-phone-volume"></i>
                            </label>
                            <input
                                value={forms.phone}
                                type="text"
                                name="phone"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={changeHandler}
                                placeholder="Your Phone No."
                                autoComplete="off" />
                            {validator.message('phone', forms.phone, 'required|phone')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="input_title" htmlFor="input_company">
                                <i className="fa-regular fa-globe"></i>
                            </label>
                            <input
                                value={forms.company}
                                type="text"
                                name="company"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={changeHandler}
                                placeholder="Your Company Name"
                                autoComplete="off" />
                            {validator.message('company', forms.company, 'required')}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="input_title" htmlFor="input_textarea">
                                <i className="fa-regular fa-comments"></i>
                            </label>
                            <textarea
                                onBlur={handleBlur}
                                onChange={changeHandler}
                                value={forms.message}
                                name="message"
                                className="form-control"
                                placeholder="How can we help you?"
                                autoComplete="off">
                            </textarea>
                            {validator.message('message', forms.message, 'required')}
                        </div>
                        <div className="form-group">
                            <label className="captcha_label" htmlFor="input_captcha">
                                Captcha: {captcha.question}
                            </label>
                            <input
                                type="text"
                                name="captcha"
                                className="form-control"
                                value={userAnswer}
                                onChange={handleCaptchaChange}
                                onBlur={validateCaptcha}
                                placeholder="Enter the result"
                                autoComplete="off" />
                           
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            <span className="btn_label" data-text="Send Request">Send Request</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-paper-plane"></i>
                            </span>
                        </button>
                        {isSubmitting && <p className="loading-message">Sending...</p>}
                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </div>
                </div>
            </form>
        </>
    );
};

export default ContactForm;
