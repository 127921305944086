
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_space_4.svg'
import shape2 from '../../../images/shapes/shape_angle_3.webp'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="faq_section section_decoration">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                        F.A.Q.
                    </div>
                    <h2 className="heading_text mb-0">
                        Need a <mark>Support?</mark>
                    </h2>
                </div>

                <div className="faq_accordion accordion" id="faq_accordion">
                    <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="1">
                                Q.What are the benefits of Custom Software Development for my business?
                            </AccordionHeader>
                            <AccordionBody accordionId="1" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Custom software development offers businesses tailored solutions that perfectly fit their unique needs, enhancing operational efficiency and scalability. Unlike off-the-shelf software, custom applications can integrate seamlessly with your existing systems, allowing for greater flexibility and control. This approach also ensures that the software can grow with your business, adapting to new requirements and avoiding the limitations of pre-packaged solutions.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="icon_list unordered_list_block">
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Web and mobile app development
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Software architecture
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    IT consulting and audit
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Legacy system modernization
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="icon_list unordered_list_block">
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Cloud computing
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    QA and testing
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    Business analysis
                                                </span>
                                            </li>
                                            <li>
                                                <span className="icon_list_icon">
                                                    <i className="fa-solid fa-circle fa-fw"></i>
                                                </span>
                                                <span className="icon_list_text">
                                                    IT staffing services
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="2">
                                Q. How can IT Consulting Services improve my company's cybersecurity?
                            </AccordionHeader>
                            <AccordionBody accordionId="2" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                IT consulting services provide expert analysis and strategic planning to strengthen your company's cybersecurity. Through services like TechGuard Audit and CyberSafe Audit, we identify potential vulnerabilities in your systems and implement robust security protocols. This proactive approach helps protect your data, ensures compliance with industry regulations, and reduces the risk of cyber threats.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="3">
                                Q. Why should I consider modernizing my Legacy Applications?
                            </AccordionHeader>
                            <AccordionBody accordionId="3" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Modernizing legacy applications is crucial for businesses looking to stay competitive in today’s fast-paced digital environment. Legacy app modernization enhances performance, security, and compatibility with new technologies, allowing your business to leverage the latest innovations. It also helps in reducing maintenance costs and improving user experience, ultimately leading to increased productivity and efficiency.
                                </p>
                                
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="4">
                                Q. What are the key advantages of Enterprise Mobile App Development?
                            </AccordionHeader>
                            <AccordionBody accordionId="4" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Enterprise mobile app development enables businesses to streamline their operations, improve employee productivity, and enhance customer engagement through mobile solutions. These apps are designed to meet specific business needs, offering features like real-time data access, secure communication, and seamless integration with existing enterprise systems. Investing in enterprise mobile apps can lead to improved workflow management and better decision-making processes.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className="accordion-item">
                            <AccordionHeader targetId="5">
                                Q. How does Data Analytics contribute to better business decisions?
                            </AccordionHeader>
                            <AccordionBody accordionId="5" className='acc_body'>
                                <div className="text_a">A.</div>
                                <p>
                                Data analytics services help businesses unlock valuable insights from their data, driving informed decision-making. By leveraging data analytics, companies can identify trends, optimize processes, and predict future outcomes. Our data analytics solutions include real-time data visualization, predictive modeling, and customized reports, which provide a comprehensive understanding of your business performance, enabling you to make strategic decisions with confidence.
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Glorious Insight Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Glorious Insight Shape Angle" />
            </div>
        </section>
    )
};
export default FaqSection;
