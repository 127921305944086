import React from 'react';
import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    {
        title: 'Our History',
        subTitle: 'Since 2017, Glorious Insight has been a visionary and trusted partner in data and AI analytics, delivering innovative solutions to world-class brands. With a focus on cutting-edge technology and strategic insights, we empower businesses to harness the power of data, driving growth and success in an increasingly digital landscape.',
        icon: sIcon1,
    },
    {
        title: 'Our Mission',
        subTitle: 'Our mission at Glorious Insight is to empower businesses by transforming data into actionable insights, driving innovation and growth through cutting-edge AI and analytics solutions. We are committed to delivering tailored, data-driven strategies that address our clients unique challenges, ensuring operational excellence and long-term success.',
        icon: sIcon2,
    },
    {
        title: 'Our Vision',
        subTitle: 'Our vision at Glorious Insight is to be a global leader in data analytics and AI-driven solutions, enabling businesses to unlock their full potential in the digital age. We aspire to set new standards in innovation, sustainability, and ethical practices, guiding our clients toward a future where technology and data work seamlessly to drive success. By embracing continuous growth and staying ahead of industry trends, we aim to shape a smarter, more connected world.',
        icon: sIcon3,
    },


]


const PolicySection = (props) => {

    return (
        <section className="policy_section bg-light">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Dollar SVG Icon" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;