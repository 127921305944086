import pImg1 from '../images/industry/industry1.webp'
import pImg2 from '../images/industry/industry2.webp'
import pImg3 from '../images/industry/industry3.webp'
import pImg4 from '../images/industry/industry4.webp'
import pImg5 from '../images/industry/industry5.webp'
import pImg6 from '../images/industry/industry6.webp'
import pImg7 from '../images/industry/industry7.webp'
import pImg8 from '../images/industry/industry8.webp'
import pImg9 from '../images/industry/industry9.webp'
import pImg10 from '../images/industry/industry10.webp'
import pImg11 from '../images/industry/industry11.webp'
import pImg12 from '../images/industry/industry12.webp'
import tImg1 from '../images/icons/co-pilot-icon.svg'
import tImg2 from '../images/icons/azure-icon.svg'




const Project = [
    {
        Id: '1',
        pImg: pImg1,
        title: 'BFSI Industry',
        slug: 'BFSI-Industry-AI-Solutions',
        sub: 'AI Solutions',
        heading1: 'Banking on the Future of Finance',
        description: 'As the banking industry embraces digital transformation, we empower financial institutions to navigate their journey towards technological innovation. With data at the forefront, we lead the next evolution in banking, driving digital advancement and reshaping the future of finance.',
        description2: 'The BFSI (Banking, Financial Services, and Insurance) industry is undergoing a significant transformation, driven by economic uncertainty, stricter regulations, and evolving investor preferences. To thrive in this new environment, financial institutions must embrace a holistic digital transformation that enhances processes, optimizes human capital, and integrates advanced technology. At Glorious Insight, we bring deep industry knowledge and specialized BFSI IT solutions to the table, empowering companies to sustain their services and achieve targeted growth. By partnering closely with your domain experts, we deliver powerful visual analytics across your organization, enabling data-driven decision-making for every user and stakeholder. Our solutions are designed to help you navigate the complexities of the modern BFSI landscape while maintaining a competitive edge.',
        feature1: 'Retail and Wholesale Banking',
        feature2: 'Cards and Payment',
        feature3: 'Insurance',
        feature4: 'Consumer Lending',
        feature5: 'Wealth Management',
        feature6: 'Forex',
        whyus1: 'We provide comprehensive, one-stop solutions for the BFSI sector, covering everything from retail and corporate banking to claims processing and investment management. Our tailored services deliver top-quality solutions across every domain within the BFSI industry, ensuring your business stays ahead in a rapidly evolving market.',
        whyus2: 'We offer precise data analytics that deliver revolutionary insights, enabling you to uncover critical information about customer identity, authenticity, and more. Our data-driven approach empowers you to make informed, profitable decisions that drive success.',
        whyus3: 'We provide 100% secure and customized solutions. At Glorious Insight, we are dedicated to delivering highly secure solutions for BFSI applications, tailored to meet the unique needs of each service and service provider.',
        usecase1title: 'Sales Analysis',
        usecase1desc: 'Our AI solutions help banks and financial institutions to optimize their operations, enhance customer service, and drive growth. We provide advanced analytics and AI-driven insights that enable you to make informed decisions, improve customer engagement, and drive profitability.',
        usecase2title: 'Lending & Transaction Analytics',
        usecase2desc: 'Our AI solutions help banks and financial institutions to optimize their operations, enhance customer service, and drive growth. We provide advanced analytics and AI-driven insights that enable you to make informed decisions, improve customer engagement, and drive profitability.',
        usecase3title: 'Risk Management',
        usecase3desc: 'Our AI solutions help banks and financial institutions to optimize their operations, enhance customer service, and drive growth. We provide advanced analytics and AI-driven insights that enable you to make informed decisions, improve customer engagement, and drive profitability.'
    },
    {
        Id: '2',
        pImg: pImg2,
        title: 'Manufacturing Industry',
        slug: 'Manufacturing-Industry',
        sub: 'AI Solutions',
        heading1: 'Revolutionizing Manufacturing with AI',
        description: 'The transport and logistics industry is constantly, Customer demand for perfection and the introduction and amalgamation of technology is bringing a new industrial revolution that is transforming the manufacturing companies. Whether you are dealing with service-components or finished goods, customers expect excellent quality at the most honest pricing. This has led the manufacturing industry to drift towards a data-driven paradigm. The new approach circles around intelligent data analytics that drive manufacturing decisions and operations.',
        description2: 'Manufacturing organizations are known to have several moving parts that generate data in each turn, This leads to the accumulation of huge volumes of data that often stays in silos and is never exploited to its highest potential. Glorious Insight serves several manufacturing companies to surmount these challenges. We provide solutions that can utilize the data for customer analytics and enterprise analytics and optimize the entire processing. We work closely with the companies and utilize our extensive experience in the industry to help collect data from various sources. With our technical expertise, we create machine learning models and powerful visualization over the data and provide actionable information. Deeper insight into every step of the process helps the organization to optimize the entire process from the acquisition of the raw material to the sale of finished goods. We provide three-tier services to achieve this goal.',
        feature1: 'Automobile',
        feature2: 'Cement',
        feature3: 'Chemical',
        feature4: 'Consumer Durables',
        feature5: 'Electrical & Electronics',
        feature6: 'Steel',
        whyus1: 'We provide comprehensive solutions to enable smart manufacturing, Glorious Insight is home to state-of-the-art technologies that effectively automate manufacturing operations, bring agility, and enhance performance significantly.',
        whyus2: 'We help you boost your efficiency with self-service solutions, Our solutions have intuitive interfaces and are designed for the self-service operation which reduces dependency on IT and elevates operational efficiency.',
        whyus3: 'We provide affordable solutions that make you future-ready, We create affordable solutions that serve businesses of all scales and capacity that delivers a competitive advantage with a clear vision into the future.',
        usecase1title: 'Efficiency Optimization',
        usecase1desc: 'Our AI solutions help manufacturers optimize their operations, enhance product quality, and drive innovation. We provide advanced analytics and AI-driven insights that enable you to make informed decisions, improve production efficiency, and drive profitability.',
        usecase2title: 'Management of supply chain risks',
        usecase2desc: 'Our AI-driven predictive maintenance solutions help manufacturers anticipate equipment failures before they occur. By analyzing data from various sources, we enable proactive maintenance strategies that minimize downtime, reduce repair costs, and extend the lifespan of machinery.',
        usecase3title: 'Planning and forecasting',
        usecase3desc: 'Our AI solutions for supply chain optimization provide end-to-end visibility and advanced forecasting. We help manufacturers streamline their supply chain processes, manage inventory more effectively, and respond swiftly to market changes, ensuring a more agile and resilient supply chain.'
    },    
    {
        Id: '3',
        pImg: pImg3,
        title: 'Education Industry',
        slug: 'Education-Industry',
        sub: 'AI Solutions',
        heading1: 'Transforming Education with AI',
        description: 'In an era of digital transformation, we empower educational institutions to harness the power of AI to enhance learning experiences and operational efficiency. As education evolves, we lead the charge in integrating advanced technologies to drive innovation and improve outcomes in the educational sector.',
        description2: 'The education industry is experiencing a profound shift due to technological advancements and changing learner expectations. Institutions must adapt by embracing digital tools and AI-driven solutions that optimize teaching methods, streamline administration, and enhance student engagement. At Glorious Insight, we offer specialized AI solutions tailored for the education sector, enabling institutions to leverage data for actionable insights. Our approach includes creating intelligent systems for personalized learning, automating administrative tasks, and providing robust analytics to support strategic decision-making. We collaborate with educators and administrators to transform educational processes and outcomes through innovative technology.',
        feature1: 'Personalized Learning',
        feature2: 'Student Engagement',
        feature3: 'Administrative Automation',
        feature4: 'Learning Analytics',
        feature5: 'Curriculum Development',
        feature6: 'Performance Tracking',
        whyus1: 'We provide innovative AI solutions for the education sector, offering tools that enhance personalized learning, streamline administrative processes, and boost student engagement. Our technology is designed to support educators and institutions in delivering a more effective and efficient educational experience.',
        whyus2: 'We enable data-driven decision-making with our advanced analytics solutions, providing actionable insights that help educational institutions improve teaching strategies, track student progress, and optimize resource allocation.',
        whyus3: 'We deliver secure and customizable AI solutions tailored to the unique needs of educational institutions. At Glorious Insight, we are committed to providing solutions that ensure data privacy and adapt to the specific requirements of each educational environment.',
        usecase1title: 'Personalized Learning',
        usecase1desc: 'Our AI solutions create customized learning experiences by analyzing student performance and adapting content to individual needs. This approach enhances learning outcomes and supports students in achieving their academic goals.',
        usecase2title: 'Administrative Automation',
        usecase2desc: 'We offer AI-driven automation solutions that streamline administrative tasks such as grading, scheduling, and student records management. These solutions reduce administrative burden and allow educators to focus more on teaching and mentoring.',
        usecase3title: 'Learning Analytics',
        usecase3desc: 'Our AI-powered learning analytics tools provide deep insights into student performance and engagement. By leveraging data, institutions can identify trends, measure effectiveness, and make informed decisions to enhance educational strategies.'
    },
    {
        Id: '4',
        pImg: pImg4,
        title: 'Enterprise Technology Industry',
        slug: 'Enterprise-Technology-Industry',
        sub: 'AI Solutions',
        heading1: 'Driving Innovation in Enterprise Technology',
        description: 'As enterprises continue to evolve, we provide cutting-edge AI solutions that drive digital transformation and operational excellence. From optimizing workflows to enhancing decision-making processes, our technology empowers organizations to stay competitive and agile in a fast-paced market.',
        description2: 'The enterprise technology landscape is rapidly advancing, with organizations seeking to leverage AI to streamline operations, enhance productivity, and gain a strategic advantage. At Glorious Insight, we offer specialized AI solutions tailored for the enterprise sector. Our services focus on integrating advanced technologies to optimize business processes, improve data management, and support strategic initiatives. We collaborate with industry leaders to deliver innovative solutions that address complex challenges and drive sustainable growth. Our expertise helps organizations harness the full potential of their data and technology investments.',
        feature1: 'Workflow Optimization',
        feature2: 'Data Management',
        feature3: 'Strategic Analytics',
        feature4: 'Automation Solutions',
        feature5: 'Business Intelligence',
        feature6: 'Operational Efficiency',
        whyus1: 'We offer comprehensive AI solutions designed to enhance enterprise operations, from workflow optimization to strategic analytics. Our technology helps businesses achieve greater efficiency, accuracy, and productivity.',
        whyus2: 'Our data management solutions provide robust insights and support informed decision-making. By leveraging advanced analytics, we help enterprises make strategic choices that drive growth and innovation.',
        whyus3: 'We are committed to delivering secure and scalable AI solutions tailored to the unique needs of enterprise organizations. Our focus on customization ensures that our technology aligns with your specific business requirements and objectives.',
        usecase1title: 'Workflow Optimization',
        usecase1desc: 'Our AI solutions streamline enterprise workflows by automating repetitive tasks and improving process efficiency. This leads to enhanced productivity and reduced operational costs.',
        usecase2title: 'Data Management',
        usecase2desc: 'We provide AI-driven data management solutions that enhance data accuracy, integration, and accessibility. Our technology supports effective data governance and facilitates better decision-making.',
        usecase3title: 'Strategic Analytics',
        usecase3desc: 'Our AI-powered analytics tools deliver actionable insights that support strategic planning and business development. By analyzing complex data sets, we help enterprises identify trends, opportunities, and areas for improvement.'
    },
    {
        Id: '5',
        pImg: pImg5,
        title: 'Media & Entertainment Industry',
        slug: 'Media-&-Entertainment-Industry',
        sub: 'AI Solutions',
        heading1: 'Transforming Media & Entertainment with AI',
        description: 'In the dynamic world of media and entertainment, our AI solutions drive innovation and enhance audience experiences. From content creation and distribution to audience engagement and data analytics, we empower media companies to stay ahead in an ever-evolving industry.',
        description2: 'The media and entertainment industry is experiencing a digital revolution, with new technologies reshaping how content is created, consumed, and monetized. At Glorious Insight, we offer advanced AI solutions tailored to the needs of this sector. Our services focus on optimizing content management, improving audience targeting, and delivering personalized experiences. We leverage our deep industry expertise to provide solutions that drive engagement, streamline operations, and unlock new revenue streams. By harnessing the power of AI, we help media and entertainment companies navigate the complexities of the digital age and achieve their strategic goals.',
        feature1: 'Content Creation and Management',
        feature2: 'Audience Analytics',
        feature3: 'Personalized Recommendations',
        feature4: 'Ad Targeting and Monetization',
        feature5: 'Real-time Insights',
        feature6: 'Streaming Optimization',
        whyus1: 'We provide comprehensive AI solutions that enhance content creation, management, and distribution. Our technology helps media companies streamline their operations and deliver high-quality content that resonates with audiences.',
        whyus2: 'Our advanced audience analytics and personalization tools enable you to understand viewer preferences and deliver tailored experiences. By leveraging data insights, we help you engage your audience more effectively and drive higher engagement rates.',
        whyus3: 'We offer secure and scalable solutions designed to meet the unique challenges of the media and entertainment industry. Our AI technology is customized to support your business objectives and adapt to the fast-changing media landscape.',
        usecase1title: 'Content Creation and Management',
        usecase1desc: 'Our AI solutions streamline content creation and management processes, enhancing efficiency and creativity. We provide tools that automate content tagging, categorization, and distribution, allowing media companies to focus on producing high-quality content.',
        usecase2title: 'Audience Analytics',
        usecase2desc: 'We offer advanced analytics tools that provide deep insights into audience behavior and preferences. Our solutions enable media companies to track viewer engagement, analyze trends, and make data-driven decisions to optimize content strategies.',
        usecase3title: 'Personalized Recommendations',
        usecase3desc: 'Our AI-driven recommendation systems deliver personalized content suggestions based on user preferences and viewing history. This enhances the viewer experience and increases content consumption, leading to higher retention and satisfaction rates.'
    },
    {
        Id: '6',
        pImg: pImg6,
        title: 'Telecom Industry',
        slug: 'Telecom-Industry',
        sub: 'AI Solutions',
        heading1: 'Driving Innovation in Telecom with AI',
        description: 'In the fast-evolving telecom industry, our AI solutions enable companies to adapt and thrive amidst growing demands and technological advancements. We empower telecom operators with data-driven insights to optimize network performance, enhance customer experience, and drive operational efficiency.',
        description2: 'The telecom industry faces numerous challenges including network optimization, customer churn, and service personalization. At Glorious Insight, we address these challenges with advanced AI solutions tailored to the telecom sector. Our technology provides predictive analytics for network management, enhances customer support through intelligent chatbots, and drives strategic decision-making with actionable insights. By leveraging our expertise, telecom companies can enhance service quality, reduce operational costs, and stay competitive in a rapidly changing market.',
        feature1: 'Network Optimization',
        feature2: 'Customer Experience Management',
        feature3: 'Fraud Detection',
        feature4: 'Predictive Maintenance',
        feature5: 'Churn Prediction',
        feature6: 'Personalized Marketing',
        whyus1: 'We offer advanced AI solutions designed to optimize network performance and reduce downtime. Our predictive analytics tools ensure efficient network management, enabling telecom operators to provide reliable and high-quality services.',
        whyus2: 'Our customer experience management solutions utilize AI to enhance customer interactions, providing personalized support and improving satisfaction. Intelligent chatbots and virtual assistants streamline customer service processes, making interactions more efficient and effective.',
        whyus3: 'We provide cutting-edge fraud detection and prevention solutions, safeguarding your telecom infrastructure from potential threats. Our AI technology identifies and mitigates fraudulent activities, ensuring the security and integrity of your operations.',
        usecase1title: 'Network Optimization',
        usecase1desc: 'Our AI solutions for network optimization analyze real-time data to enhance network performance and efficiency. By predicting potential issues and providing actionable insights, we help telecom operators maintain a high-quality network and minimize disruptions.',
        usecase2title: 'Customer Experience Management',
        usecase2desc: 'We offer AI-driven tools that enhance customer experience through personalized interactions and support. Our solutions include intelligent chatbots and virtual assistants that improve response times and customer satisfaction.',
        usecase3title: 'Predictive Maintenance',
        usecase3desc: 'Our predictive maintenance solutions use AI to foresee equipment failures before they occur. By analyzing data from network components, we enable telecom companies to perform proactive maintenance, reducing downtime and extending the lifespan of critical infrastructure.'
    },
    {
        Id: '7',
        pImg: pImg7,
        title: 'Travel & Hospitality Industry',
        slug: 'Travel-&-Hospitality',
        sub: 'AI Solutions',
        heading1: 'Transforming Travel & Hospitality with AI',
        description: 'In the dynamic travel and hospitality industry, we leverage AI to enhance customer experiences, streamline operations, and drive growth. Our solutions are designed to help travel and hospitality businesses navigate the complexities of modern tourism, offering personalized services and operational efficiencies.',
        description2: 'The travel and hospitality industry is experiencing rapid changes driven by evolving consumer preferences, technological advancements, and global competition. At Glorious Insight, we address these challenges with cutting-edge AI solutions tailored for the industry. From personalized recommendations to operational optimization, our technology enables businesses to offer exceptional customer experiences, improve operational efficiency, and stay competitive in a rapidly changing market. We work closely with industry leaders to deploy AI solutions that transform every aspect of their operations, from booking processes to customer service and beyond.',
        feature1: 'Personalized Recommendations',
        feature2: 'Customer Experience Enhancement',
        feature3: 'Dynamic Pricing Optimization',
        feature4: 'Operational Efficiency',
        feature5: 'Predictive Analytics',
        feature6: 'Fraud Detection',
        whyus1: 'We offer personalized solutions that enhance guest experiences, utilizing AI to tailor recommendations and services to individual preferences. Our technology helps you deliver a unique and memorable experience for every traveler.',
        whyus2: 'Our solutions improve operational efficiency, optimizing booking processes, resource management, and service delivery. By leveraging AI-driven insights, we help you streamline operations and reduce costs.',
        whyus3: 'We provide advanced predictive analytics and fraud detection capabilities, ensuring secure transactions and proactive management of potential issues. Our AI solutions help you stay ahead of potential risks and capitalize on emerging opportunities.',
        usecase1title: 'Personalized Recommendations',
        usecase1desc: 'Our AI-driven recommendation engine analyzes customer data to provide personalized travel and hospitality recommendations. By understanding individual preferences, we help businesses offer tailored experiences that increase satisfaction and loyalty.',
        usecase2title: 'Dynamic Pricing Optimization',
        usecase2desc: 'We use AI to optimize pricing strategies based on demand, competition, and other factors. Our dynamic pricing solutions help businesses maximize revenue and adapt to market conditions in real-time.',
        usecase3title: 'Operational Efficiency',
        usecase3desc: 'Our AI solutions enhance operational efficiency by automating routine tasks, optimizing resource allocation, and improving service delivery. By streamlining operations, we help businesses reduce costs and enhance overall performance.'
    },
    {
        Id: '8',
        pImg: pImg8,
        title: 'Healthcare Industry',
        slug: 'Healthcare',
        sub: 'AI Solutions',
        heading1: 'Revolutionizing Healthcare with AI',
        description: 'In the rapidly evolving healthcare landscape, we harness the power of AI to transform patient care, streamline operations, and drive innovation. Our solutions are designed to enhance clinical outcomes, improve patient experiences, and optimize healthcare delivery.',
        description2: 'The healthcare industry faces numerous challenges, including rising costs, regulatory pressures, and the need for improved patient outcomes. At Glorious Insight, we address these challenges with advanced AI solutions that empower healthcare providers to deliver personalized care, streamline processes, and make data-driven decisions. Our technology supports everything from predictive analytics and clinical decision support to operational efficiency and patient engagement. By integrating our solutions, healthcare organizations can navigate the complexities of modern healthcare while achieving operational excellence and better patient outcomes.',
        feature1: 'Predictive Analytics for Patient Outcomes',
        feature2: 'Clinical Decision Support Systems',
        feature3: 'Operational Efficiency Optimization',
        feature4: 'Patient Engagement and Personalization',
        feature5: 'Medical Imaging Analysis',
        feature6: 'Fraud Detection and Compliance',
        whyus1: 'We provide cutting-edge AI solutions that enhance patient care through predictive analytics and personalized treatment plans. Our technology helps healthcare providers anticipate patient needs and improve clinical outcomes.',
        whyus2: 'Our solutions optimize operational efficiency by automating routine tasks, streamlining workflows, and improving resource management. This leads to cost savings and more effective healthcare delivery.',
        whyus3: 'We offer advanced fraud detection and compliance solutions to ensure the integrity of healthcare data and processes. Our AI-driven approach helps organizations adhere to regulations and safeguard against fraudulent activities.',
        usecase1title: 'Predictive Analytics for Patient Outcomes',
        usecase1desc: 'Our AI solutions analyze patient data to predict outcomes and provide personalized treatment recommendations. By leveraging predictive analytics, healthcare providers can improve patient care and optimize treatment plans.',
        usecase2title: 'Clinical Decision Support Systems',
        usecase2desc: 'We offer AI-driven clinical decision support systems that assist healthcare professionals in making informed decisions. These systems provide actionable insights and recommendations based on patient data and clinical guidelines.',
        usecase3title: 'Operational Efficiency Optimization',
        usecase3desc: 'Our AI solutions enhance operational efficiency by automating administrative tasks, optimizing scheduling, and improving resource allocation. This results in reduced operational costs and improved service delivery.'
    },
    {
        Id: '9',
        tImg: tImg1,
        tImg2: tImg2,
        pImg: pImg9,
        title: 'Real-Time AI Recruitment & Document Verification for HPCL​',
        sub: 'AI Document Verifier',
        description: 'HPCL required a generative AI solution for processing job applications, integrating and analyzing job descriptions, application forms, and candidate documents stored in Azure Blob storage. Key features included OCR and face recognition for data extraction, ranking candidates based on document coherence, generating relevant interview questions, and verifying document authenticity through cross-referencing.',
    },    
    {
        Id: '10',
        tImg: tImg1,
        tImg2: tImg2,
        pImg: pImg10,
        title: 'Generative AI Chatbot for Information Assistance - Power Grid​',
        sub: 'AI Chatbot',
        description: 'Power Grid needed a user-friendly, Azure AI-powered generative chatbot for its website to provide predictive insights and assist users with document-based inquiries. This chatbot will handle both initial and follow-up questions conversationally, select relevant documents, and enhance user experience by streamlining document access and information retrieval for both internal and external users.',
    },    
    {
        Id: '11',
        tImg: tImg1,
        tImg2: tImg2,
        pImg:pImg11,
        title: 'Multilingual AI Tutor & Real-Time Paper Generator for Educational Support - IGNOU',
        sub: 'AI Solution',
        description: 'IGNOU sought Glorious Insight to develop a multilingual OpenAI-based chatbot to enhance educational accessibility for its diverse student body. As the world largest university, IGNOU needed to improve engagement and support in multiple languages and automate processes like question bank generation and material translation. Glorious Insights advanced AI expertise was essential for this complex challenge.',
    },
    {
        Id: '12',
        tImg: tImg1,
        tImg2: tImg2,
        pImg:pImg12,
        title: 'Multilingual Voice-Enabled Chatbot for Government Services - UPDESCO',
        sub: 'Voice Assistant',
        description: 'UPDESCO needed a bilingual, voice-activated chatbot developed with Azure technologies to simplify interactions with government services. Integrated into their website, it allows users to request, apply for, and track certificates like Birth, Death, and Marriage using Aadhar APIs for authentication, supporting both Hindi and English for improved accessibility.',
    }



]

export default Project;