import hImg1 from '../images/services/industry1.webp'
import hImg2 from '../images/services/industry2.webp'
import hImg3 from '../images/services/industry3.webp'
import hImg4 from '../images/services/industry4.webp'
import hImg5 from '../images/services/industry5.webp'
import hImg6 from '../images/services/industry6.webp'
import hImg7 from '../images/services/industry7.webp'
import hImg8 from '../images/services/industry8.webp'
import hImg9 from '../images/services/industry9.webp'
import hImg10 from '../images/services/industry10.webp'
import hImg11 from '../images/services/industry11.webp'
import hImg12 from '../images/services/industry12.webp'
import hImg13 from '../images/services/industry13.webp'
import hImg14 from '../images/services/industry14.webp'
import hImg15 from '../images/services/industry15.webp'
import hImg16 from '../images/services/industry16.webp'
import hImg17 from '../images/services/industry17.webp'
import hImg18 from '../images/services/industry18.webp'
import hImg19 from '../images/services/industry19.webp'

import icon1 from '../images/icons/icon_code.svg'
import icon2 from '../images/icons/icon_programming_tree.svg'
import icon3 from '../images/icons/icon_monitor_2.svg'
import icon4 from '../images/icons/icon_phone.svg'
import icon5 from '../images/icons/icon_bug.svg'
import icon6 from '../images/icons/icon_programming.svg'

const Services = [
    {
        Id: '1',
        hImg:hImg1,
        // pImg: pImg1,
        // pImg1: pImg2,
        // pImg2: pImg3,
        title: 'Data Management Services',
        slug: 'Data-Management-Services',
        thumb1:'Strategy',
        thumb2:'Consultation',
        col:'col-lg-6',
        description: 'At Glorious Insight, our workflow automation services harness Robotic Process Automation (RPA) and Artificial Intelligence (AI) for intelligent, efficient task handling. We use cloud-based platforms and API integrations to ensure seamless connectivity, while low-code/no-code tools accelerate customization. Real-time monitoring and reporting provide valuable insights into your automated processes, enhancing productivity and operational efficiency.',
        feature: 'Our Data Management Services utilize Robotic Process Automation (RPA) and Artificial Intelligence (AI) for efficient data handling. We employ cloud-based platforms and API integrations to ensure seamless data flow, complemented by low-code/no-code tools for rapid deployment. Real-time monitoring and reporting further enhance productivity and operational efficiency.',
        service1: 'Robotic Process Automation (RPA)',
        service2: 'Artificial Intelligence (AI) Integration',
        service3: 'Cloud-Based Platforms',
        service4: 'API Integrations',
        service5: 'Low-Code/No-Code Tools',
        service6: 'Real-Time Monitoring and Reporting',
        servicedescription: ''
    },
    {
        Id: '2',
        hImg:hImg2,
        // pImg: pImg4,
        // pImg1: pImg5,
        // pImg2: pImg6,
        title: 'Data Visualization Services',
        slug: 'Data-Visualization-Services',
        thumb1:'Management',
        thumb2:'Transfer',
        col:'col-lg-6',
        description: 'At Glorious Insight, our Data Visualization Services offer interactive dashboards and customizable reports to make data insights accessible and actionable. We provide real-time data updates to ensure your information is always current and leverage advanced analytics integration for deep insights. Our data storytelling techniques enhance data comprehension, while our scalable data solutions adapt to your growing business needs. With these features, we transform complex data into clear, impactful visuals that drive informed decision-making.',
        feature: 'Our Data Visualization Services include interactive dashboards, customizable reports, real-time data updates, advanced analytics integration, data storytelling techniques, and scalable data solutions. These features ensure you get clear, actionable insights from your data.',
        service1: 'Interactive Dashboards',
        service2: 'Customizable Reports',
        service3: 'Real-Time Data Updates',
        service4: 'Advanced Analytics Integration',
        service5: 'Data Storytelling Techniques',
        service6: 'Scalable Data Solutions',
        servicedescription: ''
    },
    {
        Id: '3',
        hImg:hImg3,
        // pImg: pImg7,
        // pImg1: pImg8,
        // pImg2: pImg9,
        title: 'Customer Data Platform',
        slug: 'Customer-Data-Platform',
        thumb1:'Landing Page',
        thumb2:'Plugins',
        col:'col-lg-4',
        description: 'At Glorious Insight, our Customer Data Platform (CDP) service centralizes and unifies customer data from multiple sources into a single view. We offer advanced data integration, real-time data processing, and robust analytics to deliver personalized customer experiences. Our platform includes powerful segmentation tools, predictive analytics, and automated campaign management to enhance your marketing efforts and drive customer engagement. With scalable solutions tailored to your business needs, we ensure you harness the full potential of your customer data.',
        feature: 'Our Customer Data Platform includes advanced data integration, real-time data processing, powerful segmentation tools, predictive analytics, and automated campaign management. These features help unify customer data and enhance marketing efforts.',
        service1: 'Advanced Data Integration',
        service2: 'Real-Time Data Processing',
        service3: 'Powerful Segmentation Tools',
        service4: 'Predictive Analytics',
        service5: 'Automated Campaign Management',
        service6: 'Scalable Solutions',
        servicedescription: ''
    },
    {
        Id: '4',
        hImg:hImg4,
        // pImg: pImg10,
        // pImg1: pImg11,
        // pImg2: pImg12,
        title: 'Business Intelligence',
        slug: 'Business-Intelligence',
        thumb1:'Consultation',
        thumb2:'solution',
        col:'col-lg-4',
        description: 'At Glorious Insight, our Business Intelligence (BI) services provide comprehensive solutions to turn your data into actionable insights. We utilize advanced analytics, data warehousing, and visualization tools to help you make informed decisions. Our BI solutions include custom reporting, interactive dashboards, and data integration to streamline your business processes and enhance strategic planning. With our expertise, you gain valuable insights and foster data-driven decision-making to drive your business forward.',
        feature: 'Our Business Intelligence services include advanced analytics, data warehousing, custom reporting, interactive dashboards, and data integration. These features help transform your data into actionable insights for informed decision-making.',
        service1: 'Advanced Analytics',
        service2: 'Data Warehousing',
        service3: 'Custom Reporting',
        service4: 'Interactive Dashboards',
        service5: 'Data Integration',
        service6: 'Strategic Planning',
        servicedescription: ''
    },
    {
        Id: '5',
        hImg:hImg5,
        // pImg: pImg14,
        // pImg1: pImg15,
        // pImg2: pImg16,
        title: 'Software architecture design',
        slug: 'Software-Architecture-Design',
        thumb1:'Website',
        thumb2:'Mobile App',
        col:'col-lg-4',
        description: 'At Glorious Insight, we offer expert Software Architecture Design services to create robust, scalable, and efficient software solutions. Our approach involves designing system architectures that support both current and future business needs, leveraging modern technologies and best practices. We focus on creating modular, maintainable, and high-performance software architectures tailored to your specific requirements, ensuring seamless integration with existing systems and facilitating long-term growth.',
        feature: 'Our Software Architecture Design services include modular system design, scalable architecture, technology stack evaluation, integration planning, performance optimization, and future-proofing strategies to ensure robust and efficient solutions.',
        service1: 'Modular System Design',
        service2: 'Scalable Architecture',
        service3: 'Technology Stack Evaluation',
        service4: 'Integration Planning',
        service5: 'Performance Optimization',
        service6: 'Future-Proofing Strategies',
        servicedescription: ''
    },
    {
        Id: '6',
        sImg: icon1,
        hImg:hImg6,
        // pImg: pImg17,
        // pImg1: pImg18,
        // pImg2: pImg19,
        title: 'System integration services',
        slug: 'System-integration-services',
        features: ['Software architecture design', 'System integration services', 'Data migration services', 'Legacy app modernization'],
        description: 'Glorious Insight provides comprehensive System Integration Services to streamline your IT infrastructure. Our expertise includes integrating disparate systems, automating workflows, and ensuring seamless data flow across platforms. We leverage APIs, middleware, and advanced integration techniques to enhance interoperability and efficiency, delivering tailored solutions that meet your specific business needs and drive operational excellence.',
        feature: 'Our System Integration Services feature seamless API integration, workflow automation, data synchronization, middleware solutions, custom integration solutions, and comprehensive testing to ensure smooth and efficient system connectivity.',
        service1: 'API Integration',
        service2: 'Workflow Automation',
        service3: 'Data Synchronization',
        service4: 'Middleware Solutions',
        service5: 'Custom Integration Solutions',
        service6: 'Comprehensive Testing',
        servicedescription: ''
    },
    {
        Id: '7',
        hImg:hImg7,
        sImg: icon2,
        // pImg: pImg2o,
        // pImg1: pImg21,
        // pImg2: pImg22,
        title: 'Data migration services',
        slug: 'Data-migration-services',
        features: ['TechGuard Audit', 'CyberSafe Audit & IT Consulting', 'AssuranceEdge & IT Consulting', 'IT Sentry Audit & IT Consulting'],
        description: 'Our Data Migration Services at Glorious Insight ensure smooth and secure transfer of your data between systems. We specialize in planning, executing, and validating data migrations to minimize downtime and data loss. Our team uses advanced tools and techniques for data extraction, transformation, and loading (ETL) to deliver accurate and reliable migration outcomes that support your business continuity and growth.',
        feature: 'Our Data Migration Services encompass data extraction, transformation, loading (ETL), data validation, migration planning, risk assessment, and post-migration support to ensure a seamless and secure transition of your data.',
        service1: 'Data Extraction',
        service2: 'Data Transformation',
        service3: 'Data Loading (ETL)',
        service4: 'Data Validation',
        service5: 'Migration Planning',
        service6: 'Post-Migration Support',
        servicedescription: ''
    },
    {
        Id: '8',
        hImg:hImg8,
        sImg: icon3,
        // pImg: pImg23,
        // pImg1: pImg24,
        // pImg2: pImg25,
        title: 'Legacy app modernization',
        slug: 'Legacy-app-modernization',
        features: ['Web app development services', 'Web portal development services', 'Website development services', 'Offshore web development'],
        description: 'Transform your outdated systems with our Legacy App Modernization services. At Glorious Insight, we breathe new life into legacy applications by incorporating modern technologies and methodologies. We offer comprehensive solutions, including web app development, web portal creation, and website development. Our offshore web development capabilities ensure cost-effective and scalable modernization strategies, aligning with your business goals.',
        feature: 'Our Legacy App Modernization services include web app development, web portal creation, and website development. We leverage modern technologies to enhance outdated systems and offer offshore development for scalable, cost-effective solutions.',
        service1: 'Web App Development Services',
        service2: 'Web Portal Development Services',
        service3: 'Website Development Services',
        service4: 'Offshore Web Development',
        service5: 'Application Re-Engineering',
        service6: 'Technology Upgrades',
        servicedescription: ''
    },
    {
        Id: '9',
        hImg:hImg9,
        sImg: icon4,
        // pImg: pImg26,
        // pImg1: pImg27,
        // pImg2: pImg28,
        title: 'TechGuard Audit',
        slug: 'TechGuard-Audit',
        features: ['Android development services', 'iOS app development services', 'Mobile application design services', 'Enterprise mobile app development'],
        feature: 'Our TechGuard Audit services include comprehensive security audits for Android and iOS apps, mobile application design, and enterprise-level app development. We identify vulnerabilities and implement best practices to ensure robust security and performance.',
        service1: 'Android Development Services',
        service2: 'iOS App Development Services',
        service3: 'Mobile Application Design Services',
        service4: 'Enterprise Mobile App Development',
        service5: 'Security Audits',
        service6: 'Performance Optimization',
        servicedescription: ''
    },
    {
        Id: '10',
        hImg:hImg10,
        sImg: icon5,
        // pImg: pImg29,
        // pImg1: pImg30,
        // pImg2: pImg31,
        title: 'CyberSafe Audit & IT Consulting',
        slug: 'CyberSafe-Audit-&-IT-Consulting',
        features: ['PixelPerfection UI/UX Design', 'DesignCraft UI/UX Design', 'CreativeWave UI/UX Design', 'InterfaceGenius UI/UX Design'],
        description: 'Protect your IT infrastructure with our CyberSafe Audit & IT Consulting services. At Glorious Insight, we offer comprehensive cybersecurity audits and IT consulting tailored to your business needs. Our services include detailed evaluations of your systems and strategic guidance to enhance your IT security posture. We employ cutting-edge UI/UX design methodologies to ensure your systems are both secure and user-friendly.',
        feature: 'Our CyberSafe Audit & IT Consulting services provide thorough cybersecurity audits and strategic IT guidance. We use advanced UI/UX design methodologies to enhance security and ensure a user-friendly experience.',
        service1: 'PixelPerfection UI/UX Design',
        service2: 'DesignCraft UI/UX Design',
        service3: 'CreativeWave UI/UX Design',
        service4: 'InterfaceGenius UI/UX Design',
        service5: 'Cybersecurity Assessments',
        service6: 'IT Strategy Consulting',
        servicedescription: ''
    },
    {
        Id: '11',
        hImg:hImg11,
        sImg: icon6,
        // pImg: pImg32,
        // pImg1: pImg33,
        // pImg2: pImg34,
        title: 'AssuranceEdge & IT Consulting',
        slug: 'AssuranceEdge-&-IT-Consulting',
        features: ['Data analytics services', 'Data visualization services', 'Data science services', 'Artificial intelligence services'],
        description: 'Leverage data to drive strategic decisions with our AssuranceEdge & IT Consulting services. Glorious Insight provides expert consulting in data analytics, visualization, and science, along with advanced AI solutions. We help you extract actionable insights from complex data sets, enhancing decision-making and fostering innovation. Our solutions are designed to align with your business objectives and provide a competitive edge.',
        feature: 'Our AssuranceEdge & IT Consulting services include data analytics, data visualization, data science, and AI solutions. We help you extract actionable insights and foster innovation to drive strategic decisions and enhance your competitive edge.',
        service1: 'Data Analytics Services',
        service2: 'Data Visualization Services',
        service3: 'Data Science Services',
        service4: 'Artificial Intelligence Services',
        service5: 'Predictive Analytics',
        service6: 'Machine Learning Integration',
        servicedescription: ''
    },
    {
        Id: '12',
        hImg:hImg12,
        // pImg: pImg35,
        // pImg1: pImg36,
        // pImg2: pImg37,
        title: 'Web App Development Services',
        slug: 'Web-App-Development-Services',
        description: 'At Glorious Insight, we specialize in web app development, providing robust and scalable solutions to meet your business needs. Our services include custom web app design and development, ensuring high performance, security, and user experience. We leverage modern technologies and development frameworks to deliver web applications that are efficient, reliable, and aligned with your business goals.',
        feature: 'Our Web App Development Services offer custom design and development of scalable web applications. We use modern technologies and frameworks to ensure high performance, security, and exceptional user experience.',
        service1: 'Custom Web App Development',
        service2: 'Responsive Web Design',
        service3: 'Front-End Development',
        service4: 'Back-End Development',
        service5: 'Web Application Security',
        service6: 'Performance Optimization',
        servicedescription: ''
    },
    {
        Id: '13',
        hImg:hImg13,
        // pImg: pImg38,
        // pImg1: pImg39,
        // pImg2: pImg40,
        title: 'Web Portal Development Services',
        slug: 'Web-Portal-Development-Services',
        description: 'Our Web Portal Development Services at Glorious Insight are designed to create robust, user-friendly portals tailored to your business needs. We offer end-to-end solutions, from initial consultation to development and deployment, ensuring seamless integration with your existing systems. Our portals are scalable, secure, and optimized for performance, helping you streamline operations and enhance user engagement.',
        feature: 'We specialize in creating scalable, secure web portals tailored to your needs. Our services include end-to-end development, ensuring seamless integration and enhanced user engagement.',
        service1: 'Custom Portal Design',
        service2: 'User Access Management',
        service3: 'Integration with Existing Systems',
        service4: 'Data Security Measures',
        service5: 'Performance Optimization',
        service6: 'Ongoing Maintenance and Support',
        servicedescription: ''
    },
    {
        Id: '14',
        hImg:hImg14,
        // pImg: pImg41,
        // pImg1: pImg42,
        // pImg2: pImg43,
        title: 'Website Development Services',
        slug: 'Website-Development-Services',
        description: 'Glorious Insight offers comprehensive Website Development Services to build visually appealing, high-performing websites. Our approach includes custom design, development, and optimization to ensure a seamless user experience. We focus on responsive design, SEO-friendly content, and robust security features to help your business establish a strong online presence and drive engagement.',
        feature: 'We provide custom website development services focusing on responsive design, SEO, and security. Our solutions ensure a seamless user experience and a strong online presence.',
        service1: 'Custom Website Design',
        service2: 'Responsive Web Design',
        service3: 'SEO Optimization',
        service4: 'E-Commerce Integration',
        service5: 'Content Management Systems',
        service6: 'Website Security and Maintenance',
        servicedescription: ''
    },
    {
        Id: '15',
        hImg:hImg15,
        // pImg: pImg44,
        // pImg1: pImg45,
        // pImg2: pImg46,
        title: 'Offshore Web Development',
        slug: 'Offshore-Web-Development',
        description: 'Our Offshore Web Development Services provide cost-effective, high-quality web solutions tailored to your business needs. At Glorious Insight, we leverage global talent to deliver scalable and innovative web development services. Our offshore team ensures timely project delivery, adherence to international standards, and seamless communication, making us a reliable partner for your web development needs.',
        feature: 'We offer cost-effective offshore web development with a focus on scalability, quality, and timely delivery. Our global team ensures adherence to international standards and seamless communication.',
        service1: 'Custom Web Development',
        service2: 'Scalable Solutions',
        service3: 'Global Talent Pool',
        service4: 'Project Management',
        service5: 'Quality Assurance',
        service6: 'Timely Delivery',
        servicedescription: ''
    },
    {
        Id: '16',
        hImg:hImg16,
        // pImg: pImg47,
        // pImg1: pImg48,
        // pImg2: pImg49,
        title: 'Automation Assessment Services',
        slug: 'Automation-Assessment-Services',
        description: 'Our Automation Assessment Services are designed to evaluate and enhance your business processes through automation. Glorious Insight provides a thorough analysis of your current workflows, identifies automation opportunities, and offers strategic recommendations for implementation. We help you optimize efficiency, reduce operational costs, and improve overall performance with tailored automation solutions.',
        feature: 'We assess your business processes to identify automation opportunities and provide strategic recommendations. Our services aim to optimize efficiency and reduce operational costs with tailored solutions.',
        service1: 'Workflow Analysis',
        service2: 'Automation Opportunity Identification',
        service3: 'Custom Automation Strategy',
        service4: 'Implementation Planning',
        service5: 'Performance Metrics',
        service6: 'Ongoing Support and Optimization',
        servicedescription: ''
    },
    {
        Id: '17',
        hImg:hImg17,
        // pImg: pImg50,
        // pImg1: pImg51,
        // pImg2: pImg52,
        title: 'Decision Automation',
        slug: 'Decision-Automation',
        description: 'Our Decision Automation services at Glorious Insight are tailored to enhance your decision-making processes through advanced automation techniques. We leverage AI and machine learning algorithms to automate complex decision workflows, ensuring faster, data-driven decisions. Our solutions integrate seamlessly with your existing systems, providing real-time insights and recommendations to optimize business outcomes.',
        feature: 'We use AI and machine learning algorithms to automate complex decision workflows. Our solutions provide real-time insights, ensuring faster, data-driven decisions that integrate seamlessly with your existing systems.',
        service1: 'AI-Driven Decision Making',
        service2: 'Machine Learning Integration',
        service3: 'Real-Time Insights',
        service4: 'Workflow Automation',
        service5: 'System Integration',
        service6: 'Custom Decision Models',
        servicedescription: ''
    },
    {
        Id: '18',
        hImg:hImg18,
        // pImg: pImg53,
        // pImg1: pImg54,
        // pImg2: pImg55,
        title: 'Content Management Automation',
        slug: 'Content-Management-Automation',
        description: 'Glorious Insight offers Content Management Automation to streamline and enhance your content workflows. Our solutions automate content creation, distribution, and management processes, ensuring consistency and efficiency. We integrate with popular content management systems and provide custom automation tools to manage large volumes of content, improve accuracy, and reduce manual effort.',
        feature: 'Our Content Management Automation streamlines content creation, distribution, and management with integrated solutions and custom automation tools. We ensure consistency, accuracy, and efficiency in handling large volumes of content.',
        service1: 'Automated Content Creation',
        service2: 'Content Distribution Automation',
        service3: 'CMS Integration',
        service4: 'Custom Automation Tools',
        service5: 'Content Accuracy Enhancement',
        service6: 'Efficiency Improvement',
        servicedescription: ''
    },
    {
        Id: '19',
        hImg:hImg19,
        // pImg: pImg56,
        // pImg1: pImg57,
        // pImg2: pImg58,
        title: 'Workflow Automation',
        slug: 'Workflow-Automation',
        description: 'Our Workflow Automation services at Glorious Insight are designed to optimize your business processes through advanced automation technologies. We utilize Robotic Process Automation (RPA), AI, and cloud-based platforms to streamline repetitive tasks, integrate systems, and provide real-time insights. Our customized solutions ensure efficient and scalable automation, enhancing overall operational productivity.',
        feature: 'We use RPA, AI, and cloud-based platforms for workflow automation. Our solutions streamline repetitive tasks, integrate systems, and provide real-time insights, ensuring efficient and scalable automation.',
        service1: 'Robotic Process Automation (RPA)',
        service2: 'Artificial Intelligence Integration',
        service3: 'Cloud-Based Automation Platforms',
        service4: 'System Integration',
        service5: 'Custom Workflow Solutions',
        service6: 'Real-Time Monitoring',
        servicedescription: ''
    },
    {
        Id: '20',
        sImg: icon1,
        title: 'Data & AI Development',
        slug: 'Data-Management-Services',
        features: ['AI Chatbots', 'BI Dashboards', 'Custom LLMs', 'Predictive Analytics']
    },
    {
        Id: '21',
        sImg: icon6,
        title: 'Custom Software Development',
        slug: 'Software-Architecture-Design',
        features: ['SaaS Development', 'CRM Solutions', 'LMS Development', 'CMS Solutions']
    },
    {
        Id: '22',
        sImg: icon3,
        title: 'IT Consultation Services',
        slug: 'TechGuard-Audit',
        features: ['Strategic IT Planning and Advisory', 'Technology Roadmap Development', 'System Integration Consulting', 'Cloud Strategy and Migration']
    },
    {
        Id: '23',
        sImg: icon4,
        title: 'Web Application Design and Development',
        slug: 'Web-App-Development-Services',
        features: ['Ios App Development', 'Android App Development', 'Hybrid App Development', 'Power App Development']
    },
    {
        Id: '24',
        sImg: icon5,
        title: 'Intelligence Automation',
        slug: 'Automation-Assessment-Services',
        features: ['Automated Reporting and Dashboards', 'AI-Driven Decision Support Systems', 'Machine Learning Model Deployment', 'Natural Language Processing (NLP)']
    },
    {
        Id: '25',
        sImg: icon2,
        title: 'Networking & Security',
        slug: 'Workflow-Automation',
        features: ['Network Design and Architecture', 'Access Control and Identity Management', 'Data Encryption and Protection', 'Firewall Configuration and Management']
    },



]    

export default Services;