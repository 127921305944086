import { useRef, useEffect } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import icon1 from '../../../images/icons/icon_c.svg'
import icon2 from '../../../images/icons/icon_g2.svg'



const testimonial = [
    {
        id: '01',
        Title: 'Revolutionary Recruitment Solution',
        Des: "The generative AI-based recruitment system developed by Glorious Insight has revolutionized our hiring process. The integration of OCR and face recognition technology streamlined document processing and improved candidate assessment accuracy. This system has been instrumental in enhancing our recruitment efficiency.",
        Name: 'IT Manager',
        sub: 'HPCL'
    },
    {
        id: '02',
        Title: 'Insightful Chatbot Integration',
        Des: "The AI-powered chatbot provided by Glorious Insight has significantly improved how we access and interpret predictive measure insights. Its seamless integration with our website and its ability to provide precise document-based answers have enhanced our decision-making process.",
        Name: 'Senior Analyst',
        sub: 'Power Grid'
    },
    {
        id: '03',
        Title: 'Enhanced Educational Interaction',
        Des: "The multilingual AI-based chatbot from Glorious Insight has greatly improved our student engagement across various languages. It has streamlined educational support and automated processes, making it easier for students to access resources and interact with the university.",
        Name: 'Director of IT',
        sub: 'IGNOU'
    },
    {
        id: '04',
        Title: 'Efficient Government Service Access',
        Des: "The bilingual, voice-activated chatbot developed by [Your Company Name] has transformed how citizens interact with government services. Its seamless integration and ability to handle both Hindi and English have significantly enhanced user accessibility and service efficiency.",
        Name: 'Project Manager',
        sub: 'UPDESCO'
    }
]

const Testimonial = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, []);

    return (

        <div className="row">
            <div className="col-lg-4">
                <div className="deals_winner_customers">
                    <h3 className="title_text">
                        <mark>3,900+</mark> Happy Faces With Us
                    </h3>
                    <div className="row">
                        <div className="col-6">
                            <div className="review_short_info">
                                <div className="icon">
                                    <img src={icon1} alt="C SVG Icon" />
                                </div>
                                <ul className="rating_block unordered_list">
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                </ul>
                                <div className="review_counter">From <b>50+</b> reviews</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="review_short_info">
                                <div className="icon">
                                    <img src={icon2} alt="C SVG Icon" />
                                </div>
                                <ul className="rating_block unordered_list">
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                                    <li><i className="fa-solid fa-star fa-fw"></i></li>
                            
                                </ul>
                                <div className="review_counter">From <b>70+</b> reviews</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-8">
                <div className="review_onecol_wrapper">
                    <div className="review_onecol_carousel">
                        <Swiper
                            // install Swiper modules
                            modules={[Navigation]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            speed={1800}
                            parallax={true}
                            ref={swiperRef}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                        >
                            {testimonial.map((testimonial, tsm) => (
                                <SwiperSlide key={tsm}>
                                    <div className="review_block_2">
                                        <h3 className="review_title">“{testimonial.Title}”</h3>
                                        <p className="review_commtent">
                                            {testimonial.Des}
                                        </p>
                                        <div className="d-md-flex justify-content-md-between">
                                            <div className="review_admin">
                                                <div className="review_admin_info">
                                                    <h4 className="review_admin_name">{testimonial.Name}</h4>
                                                    <span className="review_admin_designation">{testimonial.sub}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>


                        <div className="carousel_arrows_nav">
                            <button ref={prevRef} type="button" className="r1cc-swiper-button-prev">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                            <button ref={nextRef} type="button" className="r1cc-swiper-button-next">
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Testimonial;