import React, { Fragment, useState } from 'react';
import Services from '../../api/service'
import { useParams } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import Header from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/software-company-components/Footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import ProcessTechnology from '../../components/software-company-components/ProcessTechnology/ProcessTechnology';
import icon from '../../images/icons/icon_check_3.svg'


const ServiceSinglePage = (props) => {

    const { slug } = useParams()

    const ServiceDetails = Services.find(item => item.slug === slug)

    const [isOpen, setOpen] = useState(false)

    return (
        <Fragment>
            <Header />
            <main className="page_content service-single-page">
                <PageTitle pageTitle={ServiceDetails.title}/>
                <section className="service_details_section section_space bg-light">
                    <div className="container">
                        {/* <div className="details_item_image position-relative">
                            <img src={ServiceDetails.hImg} alt="Service Details"/>
                        </div> */}
                        <h2 className="details_item_title">{ServiceDetails.title}</h2>
                        <p>
                            {ServiceDetails.description}
                        </p>
                        <p>
                           {ServiceDetails.feature}
                        </p>
                        <h3 className="details_item_info_title">Our Expertise In {ServiceDetails.title} </h3>
                        <p className="mb-4">
                        {ServiceDetails.servicedescription}
                        </p>
                        <div className="row mb-4">
                            <div className="col-lg-6">
                                <ul className="icon_list unordered_list_block">
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon"/>
                                        </span>
                                        <span className="icon_list_text">
                                        {ServiceDetails.service1}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon"/>
                                        </span>
                                        <span className="icon_list_text">
                                        {ServiceDetails.service2}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon"/>
                                        </span>
                                        <span className="icon_list_text">
                                        {ServiceDetails.service3}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul className="icon_list unordered_list_block">
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon"/>
                                        </span>
                                        <span className="icon_list_text">
                                        {ServiceDetails.service4}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon"/>
                                        </span>
                                        <span className="icon_list_text">
                                        {ServiceDetails.service5}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon"/>
                                        </span>
                                        <span className="icon_list_text">
                                        {ServiceDetails.service6}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* <div className="details_item_image m-0">
                                    <img src={ServiceDetails.pImg} alt="Service Details"/>
                                </div> */}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* <div className="details_item_image m-0">
                                    <img src={ServiceDetails.pImg1} alt="Service Details"/>
                                </div> */}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                {/* <div className="details_item_image m-0">
                                    <img src={ServiceDetails.pImg2} alt="Service Details"/>
                                </div> */}
                            </div>
                        </div>
                        <ProcessTechnology />     
                    </div>
                </section>
            </main>
            <CtaSection />
            <Footer />
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default ServiceSinglePage;