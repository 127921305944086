import React, { Fragment } from 'react';
import Header from '../../components/Header2/Header2';
import Project from '../../api/project';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/software-company-components/Footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import icon from '../../images/icons/icon_check_3.svg'


const PortfolioSinglePage = (props) => {
    const { slug } = useParams()
    const PortfolioDetails = Project.find(item => item.slug === slug)


    return (
        <Fragment>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={PortfolioDetails.title} />
                <section className="portfolio_details_section section_space bg-light">
                    <div className="container">
                        <div className="details_item_image">
                            <img src={PortfolioDetails.pImg} alt="Portfolio Item" />
                        </div>
                        <h2 className="details_item_title">{PortfolioDetails.heading1}</h2>
                        <p>
                            {PortfolioDetails.description}
                        </p>
                        <p className="mb-0">
                            {PortfolioDetails.description2}
                        </p>
                        <hr />
                        

                        <h3 className="details_item_info_title pt-4">Our expertise extends accross the Industry with:</h3>
                        
                        <div className="row mb-4">
                            <div className="col-lg-5">
                                <ul className="icon_list unordered_list_block">
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon" />
                                        </span>
                                        <span className="icon_list_text">
                                        {PortfolioDetails.feature1}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon" />
                                        </span>
                                        <span className="icon_list_text">
                                        {PortfolioDetails.feature2}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon" />
                                        </span>
                                        <span className="icon_list_text">
                                        {PortfolioDetails.feature3}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-5">
                                <ul className="icon_list unordered_list_block">
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon" />
                                        </span>
                                        <span className="icon_list_text">
                                        {PortfolioDetails.feature4}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon" />
                                        </span>
                                        <span className="icon_list_text">
                                        {PortfolioDetails.feature5}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="icon_list_icon">
                                            <img src={icon} alt="Check SVG Icon" />
                                        </span>
                                        <span className="icon_list_text">
                                        {PortfolioDetails.feature6}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <h3 className="details_item_info_title pt-4">Why Glorious Insight?</h3>
                        <p>
                            {PortfolioDetails.whyus1}
                        </p>
                        <p>
                            {PortfolioDetails.whyus2}
                        </p>
                        <p>
                            {PortfolioDetails.whyus3}
                        </p>

                        <h3 className="details_item_info_title pt-5 mb-4">Use Cases</h3>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="use_case_item">
                                    <h4>{PortfolioDetails.usecase1title}</h4>
                                    <p>{PortfolioDetails.usecase1desc}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="use_case_item">
                                    <h4>{PortfolioDetails.usecase2title}</h4>
                                    <p>{PortfolioDetails.usecase2desc}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="use_case_item">
                                    <h4>{PortfolioDetails.usecase3title}</h4>
                                    <p>{PortfolioDetails.usecase3desc}</p>
                                </div>
                            </div>
                            </div>
                       
                    </div>
                </section>
                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PortfolioSinglePage;
