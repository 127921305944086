
import React, { useState } from 'react';
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import shape1 from '../../../images/shapes/shape_line_2.svg'
import shape2 from '../../../images/shapes/shape_line_3.svg'
import shape3 from '../../../images/shapes/shape_line_4.svg'
import shape4 from '../../../images/shapes/shape_space_3.svg'

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import TechnologySection from '../TechnologySection/TechnologySection';
import Testimonial from '../Testimonial/Testimonial';

const FaqSection = (props) => {

    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <section className="process_technology_review_section bg-light section_decoration">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6">
                        <div className="heading_block">
                            <div className="heading_focus_text has_underline d-inline-flex" style={{ backgroundImage: `url(${Bg})` }}>
                                Working Process
                            </div>
                            <h2 className="heading_text mb-0">
                                Our <mark>Approach</mark>
                            </h2>
                        </div>
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="1">
                                    01. Discovery Phase
                                </AccordionHeader>
                                <AccordionBody accordionId="1" className='acc_body'>
                                    <p className="m-0">
                                    In this initial phase, we conduct a comprehensive analysis of your requirements and objectives. Our team engages with stakeholders to understand the specific needs and challenges of the project. We perform market research and competitive analysis to identify industry best practices and emerging trends. This phase helps us define the project scope, outline key functionalities, and establish a clear roadmap for success.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="2">
                                    02. Design and Development
                                </AccordionHeader>
                                <AccordionBody accordionId="2" className='acc_body'>
                                    <p className="m-0">
                                    Our design and development process involves crafting a user-centric solution that aligns with your business goals. We create detailed wireframes and prototypes to visualize the user interface and user experience. Our development team leverages advanced technologies and frameworks, including Azure services and AI tools, to build a scalable and robust solution. Key activities include coding, integration, and ensuring the system meets all technical requirements.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="3">
                                    03. Maintenance
                                </AccordionHeader>
                                <AccordionBody accordionId="3" className='acc_body'>
                                    <p className="m-0">
                                    Post-deployment, we offer ongoing maintenance to ensure the continued performance and reliability of your solution. Our maintenance services include monitoring system health, applying updates and patches, and troubleshooting issues. We provide support to address any bugs or performance concerns, ensuring that the solution evolves with your business needs and technological advancements.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="4">
                                    04. Deployment
                                </AccordionHeader>
                                <AccordionBody accordionId="4" className='acc_body'>
                                    <p className="m-0">
                                    Our deployment phase involves seamlessly integrating the solution into your existing infrastructure. We handle the setup and configuration of the system, ensuring a smooth transition from development to production. Our deployment strategy includes data migration, user training, and the establishment of support mechanisms to facilitate a successful go-live and minimize disruption.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem className="accordion-item">
                                <AccordionHeader targetId="5">
                                    05. Testing and QA
                                </AccordionHeader>
                                <AccordionBody accordionId="5" className='acc_body'>
                                    <p className="m-0">
                                    We implement rigorous testing and quality assurance processes to ensure the solution meets the highest standards of performance and reliability. Our testing phases include functional, integration, and user acceptance testing. We use automated testing tools and manual review techniques to identify and resolve any issues before the solution is deployed. Our QA efforts ensure that the final product is robust, secure, and user-friendly.
                                    </p>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="col-lg-5">
                        <Accordion open={open} toggle={toggle} className="accordion" id="service_process_faq">
                            <ul className="content_layer_group unordered_list_block text-center">
                                <li><AccordionHeader targetId="1"><span>Discovery Phase</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="2"><span>Design and Development</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="3"><span>Maintenance</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="4"><span>Deployment</span></AccordionHeader></li>
                                <li><AccordionHeader targetId="5"><span>Testing and QA</span></AccordionHeader></li>
                            </ul>
                        </Accordion>
                    </div>
                </div>
                <TechnologySection/>
                <Testimonial/>
            </div>

            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Glorious Insight Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Glorious Insight Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Glorious Insight Shape" />
            </div>
            <div className="decoration_item shape_image_4">
                <img src={shape4} alt="Glorious Insight Shape" />
            </div>
        </section>
    )
};
export default FaqSection;
