import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const ContactForm = () => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });

    const [fieldTouched, setFieldTouched] = useState({
        name: false,
        email: false,
        phone: false,
        message: false
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [userAnswer, setUserAnswer] = useState('');
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                // Reset form fields and captcha
                setForms({ name: '', email: '', subject: '', phone: '', message: '' });
                setCaptcha(generateCaptcha());
                setUserAnswer('');
                setIsCaptchaValid(false);
                validator.reset();
                setSuccessMessage('');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    function generateCaptcha() {
        const min = 10;
        const max = 40;
        let num1 = Math.floor(Math.random() * (max - min + 1)) + min;
        let num2 = Math.floor(Math.random() * (max - min + 1)) + min;
        const operation = Math.random() > 0.5 ? '+' : '-';
        
        let answer;
        let question;
    
        if (operation === '+') {
            answer = num1 + num2;
            question = `${num1} + ${num2}`;
        } else {
            if (num1 < num2) {
                [num1, num2] = [num2, num1];
            }
            answer = num1 - num2;
            question = `${num1} - ${num2}`;
        }
    
        return {
            question: question,
            answer: answer
        };
    }
    
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
    };

    const blurHandler = e => {
        setFieldTouched({ ...fieldTouched, [e.target.name]: true });
        validator.showMessageFor(e.target.name);
    };

    const handleCaptchaChange = e => {
        setUserAnswer(e.target.value);
        validateCaptcha();
    };

    const validateCaptcha = () => {
        if (parseInt(userAnswer) === captcha.answer) {
            setIsCaptchaValid(true);
            setError(''); // Clear error state when captcha is valid
        } else {
            setIsCaptchaValid(false);
            setError('Captcha is incorrect'); // Set error state when captcha is invalid
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
    
        if (validator.allValid() && isCaptchaValid) {
            setIsSubmitting(true);
            setError('');
    
            try {
                const response = await fetch('/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(forms),
                });
    
                const contentType = response.headers.get('Content-Type');
    
                if (response.ok) {
                    if (contentType.includes('application/json')) {
                        const result = await response.json();
                        // Set success message
                        setSuccessMessage(result.message || 'Your message was sent successfully!');
                    } else {
                        const text = await response.text();
                        setSuccessMessage(text || 'Your message was sent successfully!');
                    }
                } else {
                    if (contentType.includes('application/json')) {
                        const result = await response.json();
                        setError(result.error || 'Failed to submit the form');
                    } else {
                        const text = await response.text();
                        setError(text || 'Failed to submit the form');
                    }
                }
            } catch (error) {
                console.error('Error submitting form:', error);
                setError('Error submitting form');
            } finally {
                setIsSubmitting(false);
            }
        } else {
            validator.showMessages();
        }
    };

    return (
        <>
            <form onSubmit={submitHandler}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="input_name">Full Name</label>
                            <input
                                value={forms.name}
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={changeHandler}
                                onBlur={blurHandler}
                                placeholder="Your Name"
                            />
                            {fieldTouched.name && validator.message('name', forms.name, 'required|alpha_space')}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="input_email">Your Email</label>
                            <input
                                value={forms.email}
                                type="email"
                                name="email"
                                className="form-control"
                                onChange={changeHandler}
                                onBlur={blurHandler}
                                placeholder="Your Email"
                            />
                            {fieldTouched.email && validator.message('email', forms.email, 'required|email')}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="input_phone">Your Phone</label>
                            <input
                                value={forms.phone}
                                type="text"
                                name="phone"
                                className="form-control"
                                onChange={changeHandler}
                                onBlur={blurHandler}
                                placeholder="Your Phone Number"
                            />
                            {fieldTouched.phone && validator.message('phone', forms.phone, 'required|phone')}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="input_message">Message</label>
                            <textarea
                                value={forms.message}
                                name="message"
                                className="form-control"
                                onChange={changeHandler}
                                onBlur={blurHandler}
                                placeholder="How can we help you?"
                            ></textarea>
                            {fieldTouched.message && validator.message('message', forms.message, 'required')}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="input_captcha">Captcha: {captcha.question}</label>
                            <input
                                type="text"
                                name="captcha"
                                className="form-control"
                                value={userAnswer}
                                onChange={handleCaptchaChange}
                                onBlur={validateCaptcha}
                                placeholder="Enter the result"
                            />
                            {error && <div className="errorMessage">{error}</div>}
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                            <span className="btn_label" data-text="Send Request">Send Request</span>
                            <span className="btn_icon">
                                <i className="fa-solid fa-arrow-up-right"></i>
                            </span>
                        </button>
                        {isSubmitting && <p className="loading-message">Sending...</p>}
                    </div>
                </div>
            </form>
            {successMessage && (
                <div className={`floating-success-message ${successMessage ? 'show' : ''}`}>
                    {successMessage}
                </div>
            )}
        </>
    );
};

export default ContactForm;
